<template>
  <div class="recruit-wrapper">
    <div class="banner-container">
      <h1 class="title">
        <div class="top">欢迎加入魔筷</div>
        <div class="bottom">一个有梦想的组织</div>
      </h1>
    </div>
    <div class="about-container">
      <h2 class="title">关于魔筷</h2>
      <p class="desc">
        魔筷科技，是一家快手、腾讯战略投资的直播电商供应服务企业。
      </p>
      <p class="desc">我们致力于更好地链接、赋能和服务网红与供应商群体，</p>
      <p class="desc">并为消费者带来创新、优质的社交购物体验！</p>
      <p class="desc">魔筷使命：成就有梦想的人</p>
      <p class="desc">在这里，大家享受工作，为梦想努力~</p>
    </div>

    <div class="video-container">
      <h2 class="title">“我眼中的魔筷”</h2>
      <div class="video-box">
        <video
          ref="joinVideo"
          src="https://mktv-in-cdn.mockuai.com/16296856249533468.mp4"
          controls
          class="video"
        ></video>
        <div v-show="posterVisible" class="poster" @click="play">
          <img
            src="https://mktv-in-cdn.mockuai.com/16296900119399764.jpg"
            alt=""
            class="main"
          />
          <img
            src="https://mktv-in-cdn.mockuai.com/16296862102226995.png"
            alt=""
            class="play-icon"
          />
        </div>
      </div>
    </div>
    <div class="worth-container">
      <h2 class="title">公司价值观</h2>
      <div class="items">
        <div v-for="(item, index) in worthData" :key="index" class="worth-item">
          <div class="icon-box">
            <img :src="item.icon" alt="" class="icon" />
          </div>
          <div class="text">{{ item.text }}</div>
        </div>
      </div>
    </div>
    <div class="office-container">
      <h2 class="title">办公环境</h2>
      <div class="desc">
        魔筷总部地处杭州市中心西侧—国家级海外高层次人才创新创业基地，魔筷科技园环境优美，幸福班车、员工食堂、娱乐休闲等设施配车齐全。在魔筷，小伙伴们工作时专心投入，工作之余一起享受生活。<br />这里没有“员工”、没有“领导”、没有“老板”，只有一起努力工作，享受生活，为梦想奋斗的“小伙伴”。
      </div>
      <div class="swiper">
        <div class="swiper-arrow prev"></div>
        <div class="swiper-arrow next"></div>
        <swiper :options="officeSwiperOption">
          <swiper-slide v-for="(item, index) in officeData" :key="index">
            <div class="card-item">
              <img :src="item.image" alt="" class="main" />
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <div class="atmosphere-container">
      <h2 class="title">团队氛围</h2>
      <div class="desc">
        2020年10月，魔筷顺利完成C+轮融资<br />目前魔筷已有近千名小伙伴，核心团队成员多为阿里系。在魔筷，我们始终高效地、有责任感地、主动地工作，我们心怀感恩，且永远保持乐观心态。<br />在这里，小伙伴们都会为自己量身定制一个漂漂美美的花名，而直呼花名又可让彼此间的距离更近一步……
      </div>
      <div class="swiper">
        <div class="swiper-arrow prev"></div>
        <div class="swiper-arrow next"></div>
        <swiper :options="atmosphereSwiperOption">
          <swiper-slide v-for="(item, index) in atmosphereData" :key="index">
            <div class="card-item">
              <img :src="item.image" alt="" class="main" />
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <div class="benefits-container">
      <h2 class="title">福利待遇</h2>
      <div class="desc">
        在魔筷，小伙们享受着五险一金、年终奖、绩效奖金、法定假日、假日礼物、带薪年假、期权奖励、员工旅游……各种人性化福利待遇。
      </div>
      <ul class="items">
        <li v-for="(item, index) in benefitsData" :key="index" class="item">
          <span class="icon"></span>
          <span class="text">{{ item }}</span>
        </li>
      </ul>
    </div>
    <div class="join-container">
      <div class="title">期待你加入魔筷，与我们一同为梦想努力！</div>
      <a href="mailto:hr@mockuai.com" class="email">
        <span class="text">简历投递：hr@mockuai.com</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  head: {
    title: "魔筷公司简介-公司福利待遇-魔筷团队氛围-魔筷科技",
    meta: [
      {
        name: "Keywords",
        content: "魔筷联系方式,魔筷招聘,魔筷咨询,公司环境,魔筷公司地址",
      },
      {
        name: "Description",
        content:
          "【魔筷科技-直播电商服务机构】作为电商综合服务企业,孵化运营多位主播策划专场服务,魔筷科技欢迎您的加入,成就有梦想的人！",
      },
    ],
  },
  data() {
    return {
      posterVisible: true,
      worthData: [
        {
          icon: "https://mktv-in-cdn.mockuai.com/16318756462202832.png",
          text: "客户第一",
        },
        {
          icon: "https://mktv-in-cdn.mockuai.com/16318756462243791.png",
          text: "相互成就",
        },
        {
          icon: "https://mktv-in-cdn.mockuai.com/16318756462122992.png",
          text: "敢想敢干",
        },
        {
          icon: "https://mktv-in-cdn.mockuai.com/16318756462265052.png",
          text: "专业尽责",
        },
        {
          icon: "https://mktv-in-cdn.mockuai.com/16308919891922985.png",
          text: "乐观皮实",
        },
        {
          icon: "https://mktv-in-cdn.mockuai.com/16308919891821814.png",
          text: "拼搏进取",
        },
        {
          icon: "https://mktv-in-cdn.mockuai.com/16308919891992666.png",
          text: "正直坦诚",
        },
      ],
      officeSwiperOption: {
        autoplay: {
          delay: 2000,
          disableOnInteraction: false,
        },
        loop: true,
        navigation: {
          nextEl: ".swiper-arrow.next",
          prevEl: ".swiper-arrow.prev",
        },
      },
      officeData: [
        {
          image: "https://mktv-in-cdn.mockuai.com/16373092626437093.jpg",
        },
        {
          image: "https://mktv-in-cdn.mockuai.com/16372891523659990.png",
        },

        {
          image: "https://mktv-in-cdn.mockuai.com/16372891523635113.png",
        },
        {
          image: "https://mktv-in-cdn.mockuai.com/16372891523185698.png",
        },
        {
          image: "https://mktv-in-cdn.mockuai.com/16377384740406243.png",
        },
        {
          image: "https://mktv-in-cdn.mockuai.com/16372891523295626.png",
        },
        {
          image: "https://mktv-in-cdn.mockuai.com/16372891523373298.png",
        },
      ],
      atmosphereSwiperOption: {
        slidesPerView: 3,
        spaceBetween: "3.1538461538461537%",
        autoplay: {
          delay: 2000,
          disableOnInteraction: false,
        },
        loop: true,
        navigation: {
          nextEl: ".atmosphere-container .swiper-arrow.next",
          prevEl: ".atmosphere-container .swiper-arrow.prev",
        },
      },
      atmosphereData: [
        {
          image: "https://mktv-in-cdn.mockuai.com/16372891523081132.png",
        },
        {
          image: "https://mktv-in-cdn.mockuai.com/16372891523151354.png",
        },
        {
          image: "https://mktv-in-cdn.mockuai.com/16372891523271306.png",
        },
        {
          image: "https://mktv-in-cdn.mockuai.com/16372891523223423.png",
        },
        {
          image: "https://mktv-in-cdn.mockuai.com/16372891523329618.png",
        },
        {
          image: "https://mktv-in-cdn.mockuai.com/16372891523343757.png",
        },
        {
          image: "https://mktv-in-cdn.mockuai.com/16372891523392305.png",
        },
        {
          image: "https://mktv-in-cdn.mockuai.com/16372891523424430.png",
        },
        {
          image: "https://mktv-in-cdn.mockuai.com/16372891523432132.png",
        },
        {
          image: "https://mktv-in-cdn.mockuai.com/16372891523452502.png",
        },
        {
          image: "https://mktv-in-cdn.mockuai.com/16372891523471461.png",
        },
        {
          image: "https://mktv-in-cdn.mockuai.com/16372891523483831.png",
        },
        {
          image: "https://mktv-in-cdn.mockuai.com/16372891523508727.png",
        },
        {
          image: "https://mktv-in-cdn.mockuai.com/16372891523521130.png",
        },
        {
          image: "https://mktv-in-cdn.mockuai.com/16372891523532791.png",
        },
        {
          image: "https://mktv-in-cdn.mockuai.com/16372891523573456.png",
        },
        {
          image: "https://mktv-in-cdn.mockuai.com/16372891523599700.png",
        },
        {
          image: "https://mktv-in-cdn.mockuai.com/16372891523603640.png",
        },
      ],
      benefitsData: [
        "入职即缴五险一金，转正增设绩效奖金，年底享受年终奖金",
        "带薪年假5天起，入职满一年即可享受，再满一年再加2天",
        "每月1次部门团建，outing、美食节、轰趴、旅游等超酷项目",
        "不定期开展社团、桌游、俱乐部等活动，形式丰富多彩",
        "意想不到的节假日惊喜，精心准备的生日礼品，亲手送到小伙伴手中",
      ],
    };
  },
  methods: {
    play() {
      this.posterVisible = false;
      this.$refs.joinVideo.play();
    },
  },
};
</script>

<style lang="less" scoped>
@import "../style/common.less";

.recruit-wrapper {
  padding-top: 90px;
  .banner-container {
    position: relative;
    box-sizing: border-box;
    .pxToVw(height, 680);
    // background-image: url(https://mktv-in-cdn.mockuai.com/16274876566909160.png);
    background-image: url(https://mktv-in-cdn.mockuai.com/16276374421256264.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #00092a;
    .title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      .top {
        line-height: 1.4;
        .pxToVw(font-size, 60);
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 300;
        color: #fff;
      }
      .bottom {
        line-height: 1.4;
        .pxToVw(font-size, 60);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
      }
    }
  }
  .about-container {
    position: relative;
    box-sizing: border-box;
    .pxToVw(height, 460);
    .pxToVw(padding-top, 91);
    background-color: #fff;
    &::before,
    &::after {
      content: "";
      position: absolute;
      .pxToVw(width, 33);
      .pxToVw(height, 31);
      background-size: cover;
      background-repeat: no-repeat;
    }
    &::before {
      content: "";
      .pxToVw(top, 168);
      .pxToVw(left, 638);
      background-image: url(https://mktv-in-cdn.mockuai.com/16274884476575669.png);
    }
    &::after {
      content: "";
      .pxToVw(bottom, 124);
      .pxToVw(right, 663);
      background-image: url(https://mktv-in-cdn.mockuai.com/16274884532307948.png);
    }
    > .title {
      .pxToVw(margin-bottom, 45);
      text-align: center;
      .pxToVw(line-height, 50);
      .pxToVw(font-size, 36);
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
    .desc {
      margin: 0;
      .pxToVw(line-height, 30);
      .pxToVw(font-size, 18);
      text-align: center;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
    }
  }
  .video-container {
    box-sizing: border-box;
    .pxToVw(height, 1074);
    .pxToVw(padding-top, 40);
    > .title {
      .pxToVw(margin-bottom, 78);
      text-align: center;
      .pxToVw(line-height, 50);
      .pxToVw(font-size, 36);
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
    .video-box {
      position: relative;
      .pxToVw(width, 1440);
      .pxToVw(height, 810);
      margin: 0 auto;
      .pxToVw(border-radius, 16);
      overflow: hidden;
      .video {
        width: 100%;
        height: 100%;
      }
      .poster {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        cursor: pointer;
        .main {
          width: 100%;
          height: 100%;
        }
        .play-icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          .pxToVw(width, 92);
          .pxToVw(height, 92);
          border-radius: 50%;
        }
      }
    }
  }
  .worth-container {
    box-sizing: border-box;
    .pxToVw(height, 434);
    .pxToVw(padding-top, 33);
    > .title {
      .pxToVw(margin-bottom, 78);
      text-align: center;
      .pxToVw(line-height, 50);
      .pxToVw(font-size, 36);
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
    .items {
      display: flex;
      align-items: center;
      .pxToVw(width, 1440);
      margin: 0 auto;
      .worth-item {
        .pxToVw(margin-right, 100);
        &:last-child {
          margin-right: 0;
        }
        .icon-box {
          display: flex;
          align-items: center;
          justify-content: center;
          .pxToVw(width, 120);
          .pxToVw(height, 120);
          border-radius: 50%;
          background-color: #fff;
          box-shadow: 0px 6px 19px 0px rgba(0, 0, 0, 0.06);
          .icon {
            .pxToVw(width, 72);
            .pxToVw(height, 72);
          }
        }
        .text {
          text-align: center;
          .pxToVw(margin-top, 12);
          .pxToVw(line-height, 33);
          .pxToVw(font-size, 24);
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
        }
      }
    }
  }
  .office-container {
    box-sizing: border-box;
    .pxToVw(height, 1024);
    .pxToVw(padding-top, 93);
    background-color: #f8f8f8;
    > .title {
      .pxToVw(margin-bottom, 66);
      text-align: center;
      .pxToVw(line-height, 50);
      .pxToVw(font-size, 36);
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
    .desc {
      .pxToVw(width, 1300);
      margin: 0 auto 35 * @unitVw;
      .pxToVw(line-height, 30);
      .pxToVw(font-size, 18);
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
    }
    .swiper {
      position: relative;
      .pxToVw(width, 1300);
      .pxToVw(border-radius, 16);
      overflow: hidden;
      margin: 0 auto;
      .swiper-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        .pxToVw(width, 44);
        .pxToVw(height, 44);
        background-size: cover;
        background-repeat: no-repeat;
        cursor: pointer;
        &.prev {
          .pxToVw(left, -54);
          background-image: url(https://mktv-in-cdn.mockuai.com/16274920200113393.png);
        }
        &.next {
          .pxToVw(right, -54);
          background-image: url(https://mktv-in-cdn.mockuai.com/16274920199899246.png);
        }
      }
      .card-item {
        .pxToVw(width, 1300);
        .pxToVw(height, 590);
        .pxToVw(border-radius, 16);
        // overflow: hidden;
        .main {
          width: 100%;
        }
      }
    }
  }
  .atmosphere-container {
    box-sizing: border-box;
    .pxToVw(height, 743);
    .pxToVw(padding-top, 93);
    background-color: #fff;
    > .title {
      .pxToVw(margin-bottom, 60);
      text-align: center;
      .pxToVw(line-height, 50);
      .pxToVw(font-size, 36);
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
    .desc {
      .pxToVw(width, 1300);
      margin: 0 auto 60 * @unitVw;
      .pxToVw(line-height, 30);
      .pxToVw(font-size, 18);
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
    }
    .swiper {
      position: relative;
      .pxToVw(width, 1300);
      margin: 0 auto;
      .swiper-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        .pxToVw(width, 44);
        .pxToVw(height, 44);
        background-size: cover;
        background-repeat: no-repeat;
        cursor: pointer;
        &.prev {
          .pxToVw(left, -54);
          background-image: url(https://mktv-in-cdn.mockuai.com/16274920200113393.png);
        }
        &.next {
          .pxToVw(right, -54);
          background-image: url(https://mktv-in-cdn.mockuai.com/16274920199899246.png);
        }
      }
      .card-item {
        .pxToVw(width, 406);
        .pxToVw(height, 280);
        .main {
          width: 100%;
        }
      }
    }
  }
  .benefits-container {
    box-sizing: border-box;
    .pxToVw(height, 543);
    .pxToVw(padding-top, 33);
    > .title {
      .pxToVw(margin-bottom, 60);
      text-align: center;
      .pxToVw(line-height, 50);
      .pxToVw(font-size, 36);
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
    .desc {
      .pxToVw(margin-bottom, 50);
      .pxToVw(line-height, 30);
      .pxToVw(font-size, 18);
      text-align: center;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
    }
    .items {
      // .pxToVw(margin-left, 676);
      .pxToVw(width, 600);
      margin: 0 auto;
      .item {
        display: flex;
        align-items: center;
        .pxToVw(margin-bottom, 33);
        .icon {
          .pxToVw(width, 20);
          .pxToVw(height, 15);
          .pxToVw(margin-right, 9);
          background-image: url(https://mktv-in-cdn.mockuai.com/16274939517459632.png);
          background-size: cover;
          background-repeat: no-repeat;
        }
        .text {
          .pxToVw(font-size, 18);
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
        }
      }
    }
  }
  .join-container {
    box-sizing: border-box;
    .pxToVw(height, 320);
    .pxToVw(padding-top, 115);
    background-color: #f8f8f8;
    background-image: url(https://mktv-in-cdn.mockuai.com/16274946481825353.png);
    background-size: cover;
    background-repeat: no-repeat;
    .title {
      .pxToVw(margin-bottom, 9);
      text-align: center;
      .pxToVw(line-height, 33);
      .pxToVw(font-size, 24);
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
    }
    .email {
      display: block;
      text-align: center;
      text-decoration: none;
      .pxToVw(line-height, 33);
      .pxToVw(font-size, 24);
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ff6600;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
